"use client";

import { useEffect } from "react";

// layouts
import BaseLayout from "@/layouts/BaseLayout";
import DownForMaintenance from "@/containers/DownForMaintenance";

// helpers
import { reportAppGlobalError } from "@/helpers/reportAppGlobalError";


export default function ErrorPage({ error }: {
  error: Error & { digest?: string }
  reset: () => void
}) {

  // effects
  useEffect(() => {
    reportAppGlobalError(error);
  }, [])

  return (
    <div data-testid="page-not-found">
      <BaseLayout>
        <DownForMaintenance />
      </BaseLayout>
    </div>
  )
}
